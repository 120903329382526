import { Injectable, inject } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class RealEstateService {
  private http = inject(HttpService)

  public getTypes = async () => await this.http.get(`/v2/realestate/type`, false, false, false)
    .then((response: any) => response.data.content)

  public getProperties = async () => await this.http.get(`/v1/realestate`, false, false, false)
    .then((response: any) => response.data)
    .catch((error: any) => error)

  public getPropertyDetails = async (slug: string) => await this.http.get(`/v1/realestate/${slug}`, false, false, false)
    .then((response: any) => response.data)
    .catch((error: any) => error)
}
