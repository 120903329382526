import { Injectable, inject } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class SolviiformaService {
  private http = inject(HttpService)

  public newLead = async (post: any) => await this.http.post(`/v2/solviiforma`, post, false, false, false)
    .then((response: any) => response.data)
}
