import { Component, inject } from '@angular/core'
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { CatalogService } from '@services/catalog.service'
import { RealEstateService } from '@services/realestate.service'
import { SolviiformaService } from '@services/solviiforma.service'
import { DropdownModule } from 'primeng/dropdown'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { StepperModule } from 'primeng/stepper'
import { ButtonModule } from 'primeng/button'
import { InputTextModule } from 'primeng/inputtext'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputSwitchModule } from 'primeng/inputswitch'
import { InputMaskModule } from 'primeng/inputmask'
import { InputGroupModule } from 'primeng/inputgroup'
import { InputGroupAddonModule } from 'primeng/inputgroupaddon'
import { CheckboxModule } from 'primeng/checkbox'
import { UtilsService } from '@utils/utils.service'

interface AutoCompleteCompleteEvent {
  originalEvent: Event
  query: string
}

@Component({
  selector: 'solviiforma',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    AutoCompleteModule,
    ButtonModule,
    StepperModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
    InputMaskModule,
    InputGroupModule,
    InputGroupAddonModule,
    CheckboxModule
  ],
  providers: [
    CatalogService,
    RealEstateService,
    SolviiformaService
  ],
  templateUrl: './solviiforma.component.html',
  styleUrl: './solviiforma.component.scss'
})
export class SolviiformaComponent {
  private formBuilder = inject(FormBuilder)
  private catalogService = inject(CatalogService)
  private realEstateService = inject(RealEstateService)
  private solviiformaService = inject(SolviiformaService)
  private utilsService = inject(UtilsService)

  public isSaving: boolean = false
  public processCompleted: boolean = false

  public states: object[] = []
  public municipalities: object[] = []
  public addressSuggestions: any[] = []
  public colonies: any[] = []
  public typeProperties: any[] = []

  public form = this.formBuilder.group({
    name: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    phone: [null, Validators.required],

    state: [null, Validators.required],
    municipality: [null, Validators.required],
    zipcode: [null, Validators.required],
    colony: [null, Validators.required],
    address: [null, Validators.required],
    placeId: [null],

    typeProperty: [null, Validators.required],
    area: [null, Validators.required],
    room: [null, Validators.required],
    bath: [null, Validators.required],
    debt: [null],
    amountDebt: [null],
    infonavit: [null],
    cadastral: [null],
    desiredAmount: [null],

    registrationReference: ['WEBSITE'],

    termsConditions: [false, Validators.requiredTrue],
  })

  get name() { return this.form.get('name') }
  set name(value: any) { this.name.setValue(value) }
  get email() { return this.form.get('email') }
  set email(value: any) { this.email.setValue(value) }
  get phone() { return this.form.get('phone') }
  set phone(value: any) { this.phone.setValue(value) }

  get state() { return this.form.get('state') }
  set state(value: any) { this.state.setValue(value) }
  get municipality() { return this.form.get('municipality') }
  set municipality(value: any) { this.municipality.setValue(value) }
  get zipcode() { return this.form.get('zipcode') }
  set zipcode(value: any) { this.zipcode.setValue(value) }
  get colony() { return this.form.get('colony') }
  set colony(value: any) { this.colony.setValue(value) }
  get address() { return this.form.get('address') }
  set address(value: any) { this.address.setValue(value) }
  get placeId() { return this.form.get('placeId') }
  set placeId(value: any) { this.placeId.setValue(value) }

  set typeProperty(value: any) { this.typeProperty.setValue(value) }
  get typeProperty() { return this.form.get('typeProperty') }
  set area(value: any) { this.area.setValue(value) }
  get area() { return this.form.get('area') }
  set room(value: any) { this.room.setValue(value) }
  get room() { return this.form.get('room') }
  set bath(value: any) { this.bath.setValue(value) }
  get bath() { return this.form.get('bath') }
  set debt(value: any) { this.debt.setValue(value) }
  get debt() { return this.form.get('debt') }
  set amountDebt(value: any) { this.amountDebt.setValue(value) }
  get amountDebt() { return this.form.get('amountDebt') }
  set infonavit(value: any) { this.infonavit.setValue(value) }
  get infonavit() { return this.form.get('infonavit') }
  set cadastral(value: any) { this.cadastral.setValue(value) }
  get cadastral() { return this.form.get('cadastral') }
  set desiredAmount(value: any) { this.desiredAmount.setValue(value) }
  get desiredAmount() { return this.form.get('desiredAmount') }

  get termsConditions() { return this.form.get('termsConditions') }
  set termsConditions(value: any) { this.termsConditions.setValue(value) }

  async ngOnInit() {
    this.municipality?.disable()
    this.address?.disable()

    this.catalogService.getStates()
      .then(response => this.states = response)

    this.realEstateService.getTypes()
      .then(response => this.typeProperties = response)

  }

  changeState = async () => {
    this.municipality.disable()
    this.address?.disable()
    this.municipalities = []
    this.municipality = null
    this.address = null

    if (this.state.value !== null) {
      this.catalogService.getMunicipalities(this.state.value)
        .then((response) => this.municipalities = response)

      this.municipality.enable()
    }
  }

  changeMunicipality = async () => {
    this.address = null
    this.address.disable()

    if (this.municipality.value !== null) {
      this.address.enable()
      this.changeZipcode()
    }
  }

  addressAutocomplete = async (event: AutoCompleteCompleteEvent) => {
    let state: any = {}
    let query = event.query || this.address.value

    await this.catalogService.getAddress(`${this.municipality.value}, ${this.state.value}, México`)
      .then(response => state = response[0])

    let address: string;

    if (typeof query === 'string') {
      address = `${query}, ${this.municipality.value}, ${this.state.value}, México`
    } else {
      address = query.description
    }

    let lat = state.geometry.location.lat
    let lng = state.geometry.location.lng

    await this.catalogService.getAddressAutocomplete(address, lat, lng)
      .then(response => this.addressSuggestions = response)
  }

  onSelectAddress = async (event: any) => {
    await this.catalogService.getAddress(event.value.description).then(response => {
      if (response.length >= 1) {
        this.placeId = response[0].place_id

        this.zipcode = response[0].zip
        this.changeZipcode()
      }
    })
  }

  changeZipcode = async () => {
    let filtered: any[] = []
    this.colonies = []
    this.colony = null

    if (this.zipcode.value !== null && this.zipcode.value !== '') {
      // Buscar colonias existentes por cp en nuestra plataforma
      await this.catalogService.getColoniesFeat3Prices(this.zipcode.value).then(response => filtered = [...response])

      // Buscar colonias por código postal
      await this.catalogService.getColonies(this.zipcode.value, 'zipcode').then(response => filtered = [...filtered, ...response])
    } else {
      if (this.municipality.value !== null) {
        // Buscar colonias por municipio
        await this.catalogService.getColonies(this.municipality.value, 'municipality').then(response => filtered = [...response])
      }
    }

    // Limpiar las colonias repetidas
    this.colonies = this.utilsService.removeObjectDuplicate(filtered, 'label')
  }

  test() {
    console.log(this.form.value);
  }

  sendForm = async () => {
    this.isSaving = true

    if (typeof this.address.value === "string") {
      let address: string;

      if (this.zipcode.value !== null) {
        address = `${this.address.value} ${this.colony.value} ${this.zipcode.value}`
      } else {
        address = `${this.address.value} ${this.colony.value}`
      }

      address = `${address}, ${this.municipality.value}, ${this.state.value}, México`

      await this.catalogService.getAddress(address).then(response => {
        if (response.length >= 1) {
          this.placeId = response[0].place_id
        }
      })
    } else {
      this.address = this.address.value.description
    }

    await this.solviiformaService.newLead(this.form.getRawValue()).then(response => {
      this.processCompleted = true
      setTimeout(() => this.isSaving = false, 1500);
    })
  }

  resetForm() {
    this.isSaving = false
    this.processCompleted = false
    this.municipalities = []
    this.addressSuggestions = []
    this.colonies = []

    this.municipality?.disable()
    this.address?.disable()

    this.form.reset();
  }
}
