import { Injectable, inject } from '@angular/core';
import { HttpService } from '@utils/http.service';
import { UtilsService } from '@utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  private http = inject(HttpService)
  private utils = inject(UtilsService)

  public getStates = async () => await this.http.get(`/v2/copomex/states`, false, false, false)
    .then((response: any) => this.utils.orderArrayBy(response.data, 'label'))

  public getMunicipalities = async (state: string) => await this.http.get(`/v2/copomex/municipalities?state=${state}`, false, false, false)
    .then((response: any) => this.utils.orderArrayBy(response.data, 'label'))

  public getAddress = async (address: string) => await this.http.get(`/v1/googlemapsapi/address?q=${address}`, false, false, false)
    .then((response: any) => response.data)
    .catch((error: any) => error)

  public getAddressAutocomplete = async (address: string, lat: string, lng: string) => await this.http.get(`/v1/googlemapsapi/address/autocomplete?q=${address}&lat=${lat}&lng=${lng}&country=MX`, false, false, false)
    .then((response: any) => response.data)
    .catch((error: any) => error)

  public getColoniesFeat3Prices = async (zipcode: string) => await this.http.get(`/v2/catalog/feat3_prices/colony/${zipcode}`, false, false, false)
    .then((response: any) => response.data)
    .catch((error: any) => error)

  public getColonies = async (value: string, by: string = 'municipality') => await this.http.get(`/v2/copomex/colony?by=${by}&q=${value}`, false, false, false)
    .then((response: any) => this.utils.orderArrayBy(response.data, 'label'))
}
